<template>
    <div class="random">
        <div class="header-bg"> </div>
        <div class="container">
            <Header />
            <div class="top">
                <div>
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/transcriptList' }">成绩</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="title">查看解析</div>
            </div>
            <div class="bot">
                <div class="topic">
                    <div style="margin: 10px 18px">
                        <div class="answer_card">
                            <div class="title">答题卡</div>

                        </div>

                        <div class="signlist">
                            <div style="display: flex">
                                <div class="title">答对：<span style="color: #00C94C">{{ rightCount }}</span></div>
                                <div class="title" style="margin-left: 50px">答错：<span
                                        style="color: #FF3B30">{{ errorCount }}</span></div>
                            </div>
                            <div class="listbox">
                                <div class="item" @click="handelClick(index)" v-for="(item, index) in questionList"
                                    :key="index"
                                    :class="[item.selectAnswer && item.selectAnswer.length > 0 ? (item.selectAnswer === item.rightAnswer) ? 'yes' : 'error' : 'no', index === idx ? 'new' : '']">
                                    {{ index + 1 }}
                                    <div class="current">当前</div>
                                </div>
                            </div>
                        </div>
                        <div class="setting">

                            <div class="title">设置</div>

                            <div class="item1">
                                <div>只看错题</div>
                                <el-switch v-model="showError" active-color="#00C94C" inactive-value="0" active-value="1"
                                    @change="showErrorSetting"></el-switch>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="subject">
                        <div style="display: flex;flex-direction: column;height: 100%;">
                            <div style="flex: 1;">
                                <div class="titlebox">
                                    <div class="title">{{ type }}题</div>
                                    <div class="title_pub">{{ currentQuestion.type !== 1 ? '只能选择唯一答案' : '可以选择多个答案' }}</div>
                                </div>
                                <div class="exercises">
                                    <div class="type">{{ type }}题</div>
                                    <div class="title">{{ idx + 1 }}/{{ questionList.length }} <span
                                            v-html="currentQuestion.title"></span></div>
                                </div>
                                <div class="radiobox">

                                    <!-- 单选题 -->
                                    <el-radio v-show="(currentQuestion.type !== 1)"
                                        v-for="(item, index) in (currentQuestion.options || [])" :key="item.title"
                                        class="radio" v-model="radio"
                                        :disabled="currentQuestion.selectAnswer && currentQuestion.selectAnswer.length > 0"
                                        :label="item.key">
                                        <div style="display: flex;align-items: center">

                                            <div v-if="currentQuestion.selectAnswer === item.key">
                                                <img style="width: 33px;height: 33px;flex-shrink: 0"
                                                    :src="currentQuestion.selectAnswer === currentQuestion.rightAnswer ? require('../assets/img/success.png') : require('../assets/img/error.png')" />
                                            </div>
                                            <div v-else
                                                style="flex-shrink: 0;width: 32px;height: 32px;border: 1px solid #E6E6E6;border-radius: 32px;text-align: center;line-height: 32px;cursor: pointer">
                                                {{ item.key }}</div>

                                            <div v-if="currentQuestion.selectAnswer === item.key">

                                                <div class="label success"
                                                    v-if="currentQuestion.selectAnswer === currentQuestion.rightAnswer"
                                                    v-html="item.value">


                                                </div>
                                                <div class="label error" v-else v-html="item.value">

                                                </div>

                                            </div>
                                            <div class="label" v-else v-html="item.value">


                                            </div>

                                        </div>

                                    </el-radio>

                                    <!-- 多选题 -->
                                    <el-checkbox v-show="(currentQuestion.type === 1)"
                                        v-for="(item, index) in currentQuestion.options || []" :key="index" class="radio"
                                        v-model="checked"
                                        :disabled="(currentQuestion.selectAnswer && currentQuestion.selectAnswer.length > 0)"
                                        :label="item.key">
                                        <div style="display: flex;align-items: center">

                                            <div
                                                v-if="currentQuestion.selectAnswer && currentQuestion.selectAnswer.indexOf(item.key) !== -1">
                                                <img style="width: 33px;height: 33px;flex-shrink: 0"
                                                    :src="currentQuestion.selectAnswer === currentQuestion.rightAnswer ? require('../assets/img/success.png') : require('../assets/img/error.png')" />
                                            </div>
                                            <div v-else
                                                style="flex-shrink: 0;width: 32px;height: 32px;border: 1px solid #E6E6E6;border-radius: 32px;text-align: center;line-height: 32px;cursor: pointer">
                                                {{ item.key }}</div>

                                            <div
                                                v-if="currentQuestion.selectAnswer && currentQuestion.selectAnswer.indexOf(item.key) !== -1">

                                                <div class="label success"
                                                    v-if="currentQuestion.selectAnswer === currentQuestion.rightAnswer"
                                                    v-html="item.value">

                                                </div>
                                                <div class="label error" v-else v-html="item.value">

                                                </div>

                                            </div>
                                            <div class="label" v-else v-html="item.value">

                                            </div>

                                        </div>

                                    </el-checkbox>
                                </div>


                                <div class="btn">
                                    <div
                                        style="display: flex;align-items: center;justify-content: space-between;margin-top: 20px">
                                        <div>
                                            <button class="up" @click="handelUp">上一题</button>
                                            <button class="go" @click="handelGo">下一题</button>
                                        </div>

                                        <div style="display: flex;">
                                            <div style="display: flex;align-items: center;cursor: pointer"
                                                @click="onCollect">
                                                <img style="vertical-align: middle;width: 24px;height: 24px"
                                                    :src="isCollect ? require('../assets/img/like.png') : require('../assets/img/dislike.png')" />
                                                <div style="color: rgba(60,60,67,0.6);font-size: 15px;">
                                                    {{ isCollect ? '已收藏' : '收藏' }}</div>
                                            </div>
                                            <div style="margin-left: 16px;display: flex;align-items: center;cursor: pointer"
                                                @click="handleFeeback">
                                                <img style="vertical-align: middle;width: 24px;height: 24px"
                                                    :src="require('../assets/img/error_feedback.png')" />
                                                <div style="color: rgba(60,60,67,0.6);font-size: 15px;">纠错</div>
                                            </div>
                                            <div style="margin-left: 16px;display: flex;align-items: center;cursor: pointer"
                                                @click="showNote = true">
                                                <img style="vertical-align: middle;width: 24px;height: 24px"
                                                    :src="require('../assets/img/note.png')" />
                                                <div style="color: rgba(60,60,67,0.6);font-size: 15px;">笔记</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="flex-shrink: 0;">
                                <div class="ansbox" v-if="currentQuestion.rightAnswer">
                                    <div class="answer">答案：<span class="active">{{ currentQuestion.rightAnswer }}</span></div>
                                </div>

                                <div class="parse" v-if="currentQuestion.rightAnswer">
                                    <div class="title">试题解析</div>
                                    <div class="desc" v-html="currentQuestion.analysis || '暂无解析'">
                                    </div>

                                </div>
                                 <!--笔记-->
                                 <div class="note" v-if="showNote">
                                    <div style="padding: 16px;">
                                        <el-input v-model="currentQuestion.note" type="textarea" autosize
                                            placeholder="请输入学习笔记"></el-input>
                                        <div style="text-align: right;margin-top: 5px;">
                                            <el-button size="small" type="primary" :loading="noteBtnLoading"
                                                @click="submitNote">保 存</el-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <el-dialog title="试题纠错" :visible.sync="showFeedbckDialog" width="32%" :close-on-click-modal="false"
            @open="$refs.feedbackForm.resetFields()">
            <div>
                <el-form label-position="top" ref="feedbackForm" :model="feedbackForm" :rules="feedbackFormRules">
                    <el-form-item label="问题类型" prop="type">
                        <el-radio-group v-model="feedbackForm.type">
                            <el-radio v-for="(item, index) in feedbackQuestionTypeOptions" :key="index"
                                :label="item.value">{{ item.label }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="具体描述" prop="refer">
                        <el-input v-model="feedbackForm.refer" type="textarea" rows="5"
                            placeholder="欢迎指出具体问题，并提供正确的描述，您的耐心指点是我们前进的动力。"></el-input>
                    </el-form-item>
                    <el-form-item label="上传图片">
                        <image-upload v-model="feedbackForm.imageUrl" :limit="9" :isShowTip="false"></image-upload>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="feedbackBtnLoading" @click="submitFeedback('feedbackForm')">提
                    交</el-button>
                <el-button @click="showFeedbckDialog = false">取 消</el-button>

            </span>
        </el-dialog>
        <Footer />

    </div>
</template>

<script>
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
import ImageUpload from '@/components/ImageUpload'
import { GetTextAnalysis, SubmitAnswer, CollectQuest, GetQuestCollectById,SaveQuestionFeedback, SaveQuestionNote } from "../api/text";
import { GetDict } from '@/api/list'
export default {
    data() {
        return {
            type: '单选',
            idx: 0,
            isOver: false,
            radio: null,
            checked: [],
            rightCount: 0,
            errorCount: 0,
            questionList: [],
            currentQuestion: {},
            isCollect: false,
            showError: '0',
            showFeedbckDialog: false,
            feedbackForm: {
                subjectId: null,
                type: '',
                refer: '',
                imageUrl: [],

            },
            feedbackQuestionTypeOptions: [],
            feedbackBtnLoading: false,
            showNote: false,
            noteBtnLoading: false,

            feedbackFormRules: {
                type: [
                    { required: true, message: '请选择问题类型', trigger: 'change' },
                ],
                refer: [
                    { required: true, message: '请输入问题描述', trigger: 'blur' },
                ],

            }
        }
    },
    components: {
        Header,
        ImageUpload,
        Footer,
    },

    created() {

        this.getQuestList();

    },

    methods: {

        //加载试题
        getQuestList() {

            this.rightCount = 0;
            this.errorCount = 0;
            this.idx = 0;
            GetTextAnalysis(this.$route.query.id, this.$route.query.rounds, this.showError).then(res => {


                this.questionList = res.data.list;
                this.rightCount = this.questionList.filter(item => item.selectAnswer && item.selectAnswer === item.rightAnswer).length;

                this.errorCount = this.questionList.filter(item => item.selectAnswer && item.selectAnswer !== item.rightAnswer).length;

                if (this.questionList.length > 0) {
                    this.currentQuestion = res.data[0]
                    this.initCurrentQuestion()
                }

            })
        },

        //初始化当前试题
        initCurrentQuestion() {
            this.currentQuestion = this.questionList[this.idx];
            this.checkCollect()
            this.type = this.formatType(this.currentQuestion.type)
            this.radio = ''
            SubmitAnswer({
                answer: this.currentQuestion.selectAnswer,
                subjectId: this.currentQuestion.id,
                categoryId: this.currentQuestion.questionCategory,
                status: '0',
                accountId: this.$store.state.user.category.id
            }).then(res => {

                this.questionList[this.idx].analysis = res.data.analysis;
                this.currentQuestion.analysis = res.data.analysis

                this.$forceUpdate()
            })

        },

        handelClick(index) {
            this.idx = index;
            this.initCurrentQuestion()
        },
        //只看错题
        showErrorSetting(value) {

            this.showError = value;

            this.getQuestList();

        },

        //格式化题型
        formatType(type) {

            switch (type) {

                case 1:
                    return '多选';
                case 2:
                    return '判断';

                case 3:
                    return '单选';

                default: return '单选';
            }
        },
        handelUp() {

            if (this.idx > 0) {
                this.idx--;
                this.initCurrentQuestion()
            }

        },
        handelto() {
            this.$router.push({
                path: '/home/homedetail?id=3'
            })
        },
        handelGo() {

            if (this.idx < this.questionList.length - 1) {

                this.idx++;
                this.initCurrentQuestion()

            }

        },

        //收藏
        onCollect() {

            CollectQuest({
                subjectId: this.currentQuestion.id,
                accountId: this.$store.state.user.category.id
            }).then(() => {
                this.isCollect = !this.isCollect;
            })
        },
        //检查是否收藏
        checkCollect() {

            GetQuestCollectById(this.currentQuestion.id).then(res => {

                this.isCollect = res.data;

            })
        },
        //题目纠错
        handleFeeback() {

            this.feedbackForm = {
                subjectId: this.currentQuestion.id,
                type: '',
                refer: '',
                imageUrl: [],

            }

            //加载字典
            GetDict('problem_type').then(res => {

                this.feedbackQuestionTypeOptions = res.data
            })

            this.showFeedbckDialog = true;


        },

        //提交纠错
        submitFeedback(formName) {

            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.feedbackBtnLoading = true;
                    let form = Object.assign({}, this.feedbackForm)
                    form.imageUrl = this.feedbackForm.imageUrl.split(',')
                    SaveQuestionFeedback(form).then(() => {

                        this.showFeedbckDialog = false;
                        this.$message.success('提交成功')

                    }).finally(() => {
                        this.feedbackBtnLoading = false;
                    })

                }

            })

        },
        //保存笔记
        submitNote() {

            if (!this.currentQuestion.note || this.currentQuestion.note.length === 0) {

                this.$message.error('请输入笔记内容')

                return
            }

            this.noteBtnLoading = true;
            SaveQuestionNote({ type: this.currentQuestion.type, subjectId: this.currentQuestion.id, accountId: this.$store.state.user.category.id, context: this.currentQuestion.note }).then(() => {

                this.$message.success('笔记保存成功')

            }).finally(() => {
                this.noteBtnLoading = false
            })

        }

    },

}
</script>

<style lang="less" scoped>
@import url(../assets/css/RandomPractice.css);

.top {
    /deep/ .el-breadcrumb__inner {
        color: #fff;

        &:hover {
            color: #fff;
        }
    }
}


.note {
    /deep/ .el-textarea__inner {

        background-color: transparent !important;
        color: #000;
        border: none;
        resize: none;
    }
}

.radio {

    .label {
        margin-left: 10px;
        color: #000;
        white-space: normal;
        word-wrap: break-word;
        word-break: break-all;
        line-height: 22px;

        &:hover {
            color: #3B89FF;
        }
    }

    .success {

        color: #00C94C;
    }

    .error {
        color: #FF3B30;
    }
}</style>
